<template>
  <section>
    <div class="card">
      <div class="card-body">
        <form @submit.prevent="filterWoodWing">
          <div class="row">
            <div class="col-lg-10 row">
              <div class="col-lg-1 col-md-6">
                <app-input v-model="filter.id" id="filter_id" :label="$t('system.id')"></app-input>
              </div>
              <div class="col-lg-3 col-md-6">
                <app-input v-model="filter.title" id="filter_title" :label="$t('article.title')"></app-input>
              </div>
              <div class="col-lg-2 col-md-6">
                <app-select
                  v-model="filter.site"
                  :options="sites"
                  :label="$t('article.site')"
                  id="filter_site"
                >
                </app-select>
              </div>
              <div class="col-lg-2 col-md-6">
                <app-select
                  v-model="filter.issue"
                  :options="issues"
                  id="filter_issue"
                  :label="$t('woodWing.issue')"
                ></app-select>
              </div>
              <div class="col-lg-2 col-md-6">
                <app-datepicker
                  type="date"
                  v-model="filter.dateFrom"
                  id="filter_dateFrom"
                  :label="$t('article.filter.published_since')"
                  disable-form-group
                ></app-datepicker>
              </div>
              <div class="col-lg-2 col-md-6">
                <app-datepicker
                  type="date"
                  v-model="filter.dateTo"
                  id="filter_dateTo"
                  :label="$t('article.filter.published_until')"
                  disable-form-group
                ></app-datepicker>
              </div>
            </div>
            <div class="col-lg-2 text-nowrap">
              <button class="btn btn-success pull-right m-t-30">
                {{ $t('buttons.search') }}
              </button>
              <button
                type="button"
                class="btn btn-secondary m-t-30"
                data-test="reset_filter"
                @click="resetFilter"
              >
                {{ $t('buttons.reset_filter') }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
import appInput from '../form/inputs/Input'
import appDatepicker from '../form/Datepicker'
import appSelect from '../form/select/Select'
import WoodWingFilter from '../../model/WoodWingFilter'
import { MODULE_ARTICLE_OFFER_WOOD_WING } from '@/model/ValueObject/UserPermissionModules'

export default {
  name: 'WoodWingFilter',
  data () {
    return {
      filter: this.$store.getters['woodWing/filter']
    }
  },
  components: {
    appInput,
    appDatepicker,
    appSelect
  },
  computed: {
    sites () {
      return this.$store.getters['site/enabledSites'](MODULE_ARTICLE_OFFER_WOOD_WING)
    }
  },
  methods: {
    issues () {
      const issues = this.$store.getters['woodWing/issues']
        .filter((issue) => !this.filter.site || issue.site === this.filter.site)
      if (issues.some((issue) => this.filter.issue === issue.id) === false) {
        this.filter.issue = null
      }
      return issues
    },
    filterWoodWing () {
      this.$store.commit('woodWing/setPage', 1)
      this.$store.commit('woodWing/setFilter', this.filter)
      this.$store.dispatch('woodWing/fetch')
    },
    resetFilter () {
      this.filter = this._.cloneDeep(WoodWingFilter)
      this.$store.commit('woodWing/setFilter', this.filter)
      this.$store.dispatch('woodWing/fetch')
    }
  }
}
</script>

<style scoped lang="scss">
  .col-lg-10 > div {
    padding-right: 0;
  }
</style>
